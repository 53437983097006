import { useFormik } from "formik";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";

import { InputText } from "@/components/input/InputText";
import { useAuth } from "@/context/auth";
import UserContext from "@/context/usercontext";
import { SERVICE_AUTHENTICATION } from "@/services";
import { CONSTANTS } from "@/utils";
import { cookie } from "@/utils/global";
import { errorNotif, successNotif } from "@/utils/notifications";

const FormLogin = () => {
	//  ===================================== STATE
	const router = useRouter();
	const { auth, handleSetAuth, isAuthenticated } = useAuth();
	const [submitLoading, setSubmitLoading] = useState(false);
	const user = useContext(UserContext);

	//  ===================================== FORMIK
	const schema = Yup.object().shape({
		email: Yup.string()
			.email("alamat email tidak valid")
			.required(
				user && user.lang === "id" ? "Email wajib diisi" : "Email is required",
			),
		password: Yup.string()
			.min(6)
			.required(
				user && user.lang === "id"
					? "Password wajib diisi"
					: "Password is required",
			),
	});

	const initialValues = {
		email: "",
		password: "",
	};

	const formik = useFormik({
		initialValues,
		validationSchema: schema,
		onSubmit: (values) => {
			setSubmitLoading(true);
			const data = {
				email: values.email,
				password: values.password,
			};

			SERVICE_AUTHENTICATION.login(data, (res: any) => {
				const { payload } = res;
				if (payload.success) {
					cookie.set(CONSTANTS.TOKEN, payload.token, { path: "/" });
					const { id, name, email, photo, token } = payload;
					handleSetAuth({
						id,
						name,
						email,
						photo,
						token,
					});
					successNotif("Login Success");
					router.push("/");
				} else {
					errorNotif(payload.message);
				}
				setSubmitLoading(false);
			});
		},
	});

	//  ===================================== EFFECT FUNCTIONS
	useEffect(() => {
		if (isAuthenticated) {
			router.push("/");
		}
	}, [auth]);

	return (
		<form
			className="flex flex-col gap-y-2 xl:gap-y-[0.4vw]"
			onSubmit={formik.handleSubmit}
		>
			<div className="">
				<InputText
					name="email"
					type="email"
					variant="outlined"
					placeholder="Type Email"
					value={formik.values.email}
					onChange={formik.handleChange}
					meta={formik.getFieldMeta("email")}
				/>
			</div>
			<InputText
				name="password"
				type="password"
				variant="outlined"
				placeholder="Type Password"
				value={formik.values.password}
				onChange={formik.handleChange}
				meta={formik.getFieldMeta("password")}
			/>

			<button
				type="submit"
				disabled={submitLoading}
				className="mx-auto my-[0.6rem] w-full rounded-[0.3rem] bg-blue-ipbt px-[1.2rem] py-4 text-[0.8rem] font-medium text-white xs:text-[1.2rem] xl:my-[0.6vw] xl:rounded-[0.2vw] xl:py-[0.8vw] xl:text-[1vw]"
			>
				Login
			</button>
			<div className="flex justify-between">
				<div className="py-[0.6vw]">
					<div className="flex gap-x-4 xl:gap-x-[0.8vw]">
						<input
							type="checkbox"
							className="custom-control-input size-6 xl:size-[1.5vw]"
							id="ingat-saya"
							name="ingat-saya"
						/>
						<label
							className="custom-control-label text-[0.8rem] xs:text-[1.2rem] xl:text-[1vw]"
							htmlFor="ingat-saya"
						>
							Ingat saya
						</label>
					</div>
				</div>
				<div className="py-[0.6vw]">
					<Link
						rel="canonical"
						href={{ pathname: "/forget-password" }}
						className="text-[0.8rem] text-blue-ipbt xs:text-[1.2rem] xl:text-[1vw]"
					>
						Lupa password
					</Link>
				</div>
			</div>
			<Link href={"/register"}>
				<button
					type="button"
					disabled={submitLoading}
					className="mx-auto mb-8 mt-[6px] w-full rounded-[0.3rem] border border-blue-ipbt bg-white px-[1.2rem] py-4 text-[0.8rem] font-medium text-black xs:text-[1.2rem] xl:rounded-[0.2vw] xl:px-[1vw] xl:py-[0.8vw] xl:text-[1vw]"
				>
					Buat Akun Baru
				</button>
			</Link>
		</form>
	);
};

export default FormLogin;
