import Swal from "sweetalert2";

export const successNotif = (response: string, onClickOk?: () => void) => {
	Swal.fire({
		title: "Success",
		text: response,
		icon: "success",
	}).then((result) => {
		if (result.isConfirmed) {
			if (onClickOk) {
				onClickOk();
			}
		}
	});
};

export const warningNotif = (response: string) => {
	Swal.fire({
		title: "Perhatian",
		text: response,
		icon: "warning",
		// button: true
	});
};

type IErrorNotif = {
	response: {
		data: {
			message: string;
		};
		status: number;
		statusText: string;
	};
};

export const errorNotif = (response: string) => {
	Swal.fire({
		title: "Oops",
		text: response,
		icon: "error",
	});
};

export const errorNotifExceptional = (error: IErrorNotif) => {
	Swal.fire({
		title: "Oops",
		text:
			(typeof error === "string" && error) ||
			(!error.response && "Network Error") ||
			error.response?.data.message ||
			`Error ${error.response.status}: ${error.response.statusText}`,
		icon: "error",
	});
};
