import classNames from "classnames";
import type { HTMLProps } from "react";

import type { FieldMetaProps } from "@/utils/definitions";

type InputTextProps = HTMLProps<HTMLInputElement> & {
	id?: string;
	type?: "text" | "number" | "password" | "email";
	className?: string;
	meta?: FieldMetaProps;
	classNameContainer?: string;
	variant?: "solid" | "outlined";
};

export function InputText({
	classNameContainer,
	meta,
	variant = "solid",
	...props
}: InputTextProps) {
	return (
		<div className={classNameContainer}>
			{variant === "outlined" && (
				<label htmlFor={props.name} className="mb-2 block">
					{props.placeholder}
				</label>
			)}
			<input
				{...props}
				className={classNames(
					"h-[42px] w-full rounded-[0.3rem] border xl:py-[0.8rem] xl:text-[1vw] xl:px-[1vw] xs:text-[0.8rem] outline-hidden",
					{
						"bg-slate-200 px-5 py-4 shadow-sm": variant === "solid",
						"px-5 py-4": variant === "outlined",
					},
					props.className,
				)}
			/>
			{meta?.touched && meta.error && (
				<div
					className={
						"text-danger text-[0.6rem] font-medium text-red-500 xl:text-[0.9vw] "
					}
				>
					<span>{meta.error}</span>
				</div>
			)}
		</div>
	);
}
